import { SLoading } from './Loading.styled'
import { TextBody } from 'driverama-core/components/text/Text'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'

interface LoadingProps {
  title?: string
}

export function Loading({ title }: LoadingProps) {
  return (
    <SLoading>
      <SpinnerCentered />
      {title && <TextBody size="large">{title}</TextBody>}
    </SLoading>
  )
}
