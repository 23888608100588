import styled from '@emotion/styled'
import { size, spaceY } from 'driverama-core/styles/spacing'

export const SLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${size(100)};
  ${spaceY(6)}
`
