import { useQuery, UseQueryOptions } from 'react-query'

import { paths } from 'driverama-core/api/driverama/generated/opportunities'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiAuthFetcher } from 'utils/authFetcher'

import { URLS } from 'constants/api'
import { QUERY_KEYS } from '../../../../constants/queryKeys'

export type OpportunitiesSellingSearchBody = Pick<
  paths['/admin/opportunities/selling/search']['post']['requestBody']['content']['application/json'],
  'sellingOpportunityIds'
>
export type OpportunitiesSellingSearchResponse =
  | paths['/admin/opportunities/selling/search']['post']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type QueryOpts = UseQueryOptions<
  unknown,
  unknown,
  OpportunitiesSellingSearchResponse
>

export function useOpportunitiesSellingSearchQuery(
  body: OpportunitiesSellingSearchBody,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.opportunitiesSellingSearch(body),
    async () => {
      const res = await apiAuthFetcher<OpportunitiesSellingSearchResponse>(
        URLS.opportunitiesSellingSearch,
        {
          method: HTTPMethod.POST,
          body
        }
      )

      return res.json
    },
    opts
  )
}
