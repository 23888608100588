import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import {
  components,
  operations
} from 'driverama-core/api/driverama/generated/opportunities'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { UseQueryOptions, useQuery } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

export type OpportunitiesSearchResponse = operations['searchOpportunities']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = {
  ids: string[]
}
type QueryOpts = UseQueryOptions<unknown, unknown, OpportunitiesSearchResponse>

export async function fetchOpportunitiesSearch(body: QueryBody) {
  const res = await apiAuthFetcher<OpportunitiesSearchResponse>(
    URLS.opportunitiesSearch,
    { method: HTTPMethod.POST, body }
  )

  return res.json
}

export function useOpportunitiesSearchQuery(body: QueryBody, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.opportunitiesSearch(body),
    () => fetchOpportunitiesSearch(body),
    opts
  )
}

export function getOpportunity(
  opportunities: components['schemas']['AdminOpportunitySearchResponse'][],
  opportunityId: string
) {
  return opportunities.find(x => x.id === opportunityId)
}
